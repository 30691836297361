import { render, staticRenderFns } from "./search-box.vue?vue&type=template&id=0ab44956&scoped=true"
import script from "./search-box.vue?vue&type=script&lang=js"
export * from "./search-box.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ab44956",
  null
  
)

export default component.exports